import React, { useCallback, useContext } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Card, ListGroup, ListGroupItem, Button, Modal, Alert, Form, ModalDialog } from "react-bootstrap"
import { UserContext } from "../context/UserContext"
import { useForm } from "react-hook-form"
import Scale from "./Scale"
import Draggable from "react-draggable";
import ScaleGateway from "./ScaleGateway";
import { FaEdit } from "react-icons/fa"
import { HiDuplicate } from "react-icons/hi"
import { FaTrash } from "react-icons/fa"


const GENERIC_ERROR_MESSAGE = "Oops... Something went wrong!";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".modal-header"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export default function Gondola(props) {
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [errorAlertMessage, setErrorAlertMessage] = useState(GENERIC_ERROR_MESSAGE)
    const [gondolaInfo, setGondolaInfo] = useState(null)
    const [scalesCount, setScalesCount] = useState(0)
    const [showEditGondolaModal, setShowEditGondolaModal] = useState(false)
    const [showAddGatewayModal, setShowAddGatewayModal] = useState(false)
    const [showAddScaleModal, setShowAddScaleModal] = useState(false)
    const [showDeleteGondolaModal, setShowDeleteGondolaModal] = useState(false)
    const [showDuplicateGondolaModal, setShowDuplicateGondolaModal] = useState(false)
    const [addScaleResultMessages, setAddScaleResultMessages] = useState([])
    const [disabledButtons, setDisabledButtons] = useState(false)

    const { register: registerFormGondola, handleSubmit: handleSubmitFormGondola, reset: resetFormGondola, setValue: setValueFormGondola } = useForm()
    const { register: registerFormGateway, handleSubmit: handleSubmitFormGateway, reset: resetFormGateway } = useForm()
    const { register: registerFormScale, handleSubmit: handleSubmitFormScale, reset: resetFormScale } = useForm()

    const { token, userenv, setToken } = useContext(UserContext)

    const handleCloseEditGondola = () => { setShowErrorAlert(false); resetFormGondola(); setShowEditGondolaModal(false); };
    const handleShowEditGondola = () => { resetFormGondola(); setShowEditGondolaModal(true) };

    const handleCloseGatewayModal = () => { setShowErrorAlert(false); resetFormGateway(); setShowAddGatewayModal(false); };
    const handleShowGatewayModal = () => { resetFormGateway(); setShowAddGatewayModal(true) };

    const handleCloseScaleModal = () => { setShowErrorAlert(false); resetFormScale(); setShowAddScaleModal(false); setAddScaleResultMessages([]) };
    const handleShowScaleModal = () => { resetFormScale(); setShowAddScaleModal(true) };


    const fetchGondolaInfo = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/gondolas/${props.gondola_id}`,
            {
                headers: new Headers({
                    "Authorization": "Bearer " + token
                })
            })
        if (response.status === 401) setToken(null)
        if (!response.ok) {
            setShowErrorAlert(true)
            setErrorAlertMessage(GENERIC_ERROR_MESSAGE)
            return
        }
        const gondola = await response.json()
        setGondolaInfo(gondola)
        setScalesCount(gondola.scales.length)
        setDisabledButtons(false)
    }, [props.storeId, props.gondola_id, token, userenv, setToken])

    useEffect(() => { fetchGondolaInfo() }, [fetchGondolaInfo, props.storeId, scalesCount, props.gondola_id, token, userenv])

    const onSubmitGateway = (data) => {
        let isResponseOK = false
        setDisabledButtons(true)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}/gateways`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            })
            .then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    isResponseOK = true
                    fetchGondolaInfo()
                    handleCloseGatewayModal()
                }
                return response.json()
            }).then(response_json => {
                if (!isResponseOK) {
                    setDisabledButtons(false)
                    setErrorAlertMessage(response_json.detail)
                    setShowErrorAlert(true)
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onSubmitScale = (data) => {
        let isResponseOK = false
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}` +
            `/gondolas/${props.gondola_id}/scales/${data.serial_number}`, {
            method: 'POST',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        }).then(response => {
            if (response.status === 401) setToken(null)
            if (response.ok) {
                isResponseOK = true
                setScalesCount(scalesCount + 1)
                setAddScaleResultMessages([...addScaleResultMessages, "Added scale " + data.serial_number + " successfully."])
            }
            return response.json()
        }).then(response_json => {
            if (!isResponseOK) {
                setAddScaleResultMessages([...addScaleResultMessages, "Error: " + response_json.detail])
            }
        }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onSubmitGondola = (data) => {

        let newGondolaInfo = gondolaInfo
        newGondolaInfo.gondola_external_id = data.gondola_external_id
        newGondolaInfo.img_pos_x = data.img_pos_x
        newGondolaInfo.img_pos_y = data.img_pos_y
        newGondolaInfo.dimensions_x = data.dimensions_x
        newGondolaInfo.dimensions_y = data.dimensions_y
        newGondolaInfo.yaw = data.yaw

        let updateGondolaJson = {
            "gondola_id": gondolaInfo.gondola_id,
            "gondola_backend_id": gondolaInfo.backend_id,
            "gondola_external_id": data.gondola_external_id,
            "img_pos_x": data.img_pos_x,
            "img_pos_y": data.img_pos_y,
            "dimensions_x": data.dimensions_x,
            "dimensions_y": data.dimensions_y,
            "yaw": data.yaw
        }

        props.clearGondolas()
        setShowEditGondolaModal(false)
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas`,
            {
                method: 'PATCH',
                body: JSON.stringify(updateGondolaJson),
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            })
            .then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    setGondolaInfo(newGondolaInfo)
                    handleCloseEditGondola()
                } else {
                    setShowErrorAlert(true)
                }
                props.fetchAndDrawGondolas();
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onClickRemoveGondola = (e) => {
        let isResponseOK = false
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            }).then(response => {
                if (response.status === 401) setToken(null)
                if (response.ok) {
                    props.clearGondolas()
                    isResponseOK = true;
                    props.fetchAndDrawGondolas();
                    setShowDeleteGondolaModal(false);
                }
                return response.json()
            }).then(response_json => {
                if (!isResponseOK) {
                    setErrorAlertMessage(response_json.detail);
                    setShowErrorAlert(true);
                }
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    const onClickDuplicateGondola = (e) => {
        setShowDuplicateGondolaModal(false)
        props.clearGondolas()
        fetch(`${process.env.REACT_APP_STORE_DEPLOYMENT_API_URL}/store-deployments/${userenv}/${props.storeId}/gondolas/${props.gondola_id}/duplicate`,
            {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-type": "application/json"
                })
            }).then(response => {
                if (response.status === 401) setToken(null)
                if (!response.ok) {
                    setShowErrorAlert(true)
                }
                props.fetchAndDrawGondolas();
            }).catch(err => { console.log(err); alert(GENERIC_ERROR_MESSAGE) })
    }

    return (
        <div>
            {showErrorAlert &&
                <Alert className="App-labels" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                    {errorAlertMessage}
                </Alert>}
            {!gondolaInfo &&
                <Card className="App-labels mb-5">
                    <Card.Body className="Camera-card-body">
                        <Card.Title className="mb-4">Gondola Details</Card.Title>
                        <Alert variant="warning">
                            Fetching gondola details...
                        </Alert>
                    </Card.Body>
                </Card>
            }
            {gondolaInfo &&
                <Card className="App-labels mb-5 ">
                    <Card.Body className="Camera-card-body">
                        <Card.Title className="mb-3">Gondola {gondolaInfo.gondola_external_id}&nbsp; &nbsp;
                            <Button disabled={disabledButtons} variant="primary" onClick={handleShowEditGondola} size="sm">
                                <FaEdit />
                            </Button>
                            &nbsp;
                            <Button disabled={disabledButtons} variant="warning" onClick={() => setShowDuplicateGondolaModal(true)} size="sm">
                                <HiDuplicate />
                            </Button>
                            &nbsp;
                            <Button disabled={disabledButtons} variant="danger" onClick={() => setShowDeleteGondolaModal(true)} size="sm">
                                <FaTrash />
                            </Button>
                        </Card.Title>

                        <Form>
                            <ListGroup className="mt-3 mb-3">
                                <ListGroupItem>
                                    External Id: &nbsp; {gondolaInfo.gondola_external_id}
                                </ListGroupItem>
                                {gondolaInfo.backend_id &&
                                    <ListGroupItem>
                                        Backend Id: &nbsp; {gondolaInfo.backend_id}
                                    </ListGroupItem>
                                }
                                <ListGroupItem>
                                    Cameras: &nbsp; {'[' + gondolaInfo.cameras.map(id => id.toString()).join(', ') + ']'}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Calibrated: &nbsp; &nbsp;
                                    <Form.Check inline disabled checked={gondolaInfo.is_calibrated} type="checkbox" />
                                </ListGroupItem>
                                <ListGroupItem>
                                    Scale Count: &nbsp; {scalesCount}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Gateway Count: &nbsp; {gondolaInfo.gateways.length}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Position (pixels): &nbsp; {gondolaInfo.img_pos_x}, {gondolaInfo.img_pos_y}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Dimensions (pixels): &nbsp; {gondolaInfo.dimensions_x}, {gondolaInfo.dimensions_y}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Rotation (angle): &nbsp; {gondolaInfo.yaw}
                                </ListGroupItem>
                            </ListGroup>
                        </Form>
                        <Button disabled={disabledButtons} variant="primary" onClick={handleShowGatewayModal} size="sm">
                            Add Gateway
                        </Button>
                        &nbsp; &nbsp;
                        {gondolaInfo && gondolaInfo.gateways.length > 0 &&
                            <Button  disabled={disabledButtons} variant="primary" onClick={handleShowScaleModal} size="sm">
                                Add Scales
                            </Button>}
                    </Card.Body>
                </Card>}

            {
                gondolaInfo && gondolaInfo.scales.map(scale =>
                    <Scale key={scale.scale_id} storeId={props.storeId}
                        gondola_id={gondolaInfo.gondola_id}
                        scale={scale}
                        gateways={gondolaInfo.gateways}
                        fetchGondolaInfo={fetchGondolaInfo}
                        disabledButtons={disabledButtons}
                        setDisabledButtons={setDisabledButtons}
                        maxVerticalPosition={gondolaInfo.scales.length}/>)
            }

            {
                gondolaInfo && gondolaInfo.gateways.map(gateway =>
                    <ScaleGateway key={gateway.scale_gateway_id} storeId={props.storeId}
                        gondola_id={gondolaInfo.gondola_id} gateway={gateway} fetchGondolaInfo={fetchGondolaInfo} />)
            }

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showEditGondolaModal} onHide={handleCloseEditGondola}>
                <Form noValidate onSubmit={handleSubmitFormGondola(onSubmitGondola)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Gondola Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.EditScale">
                            {gondolaInfo !== null && setValueFormGondola("gondola_external_id", gondolaInfo.gondola_external_id)}
                            {gondolaInfo !== null && setValueFormGondola("img_pos_x", gondolaInfo.img_pos_x)}
                            {gondolaInfo !== null && setValueFormGondola("img_pos_y", gondolaInfo.img_pos_y)}
                            {gondolaInfo !== null && setValueFormGondola("yaw", gondolaInfo.yaw)}
                            {gondolaInfo !== null && setValueFormGondola("dimensions_x", gondolaInfo.dimensions_x)}
                            {gondolaInfo !== null && setValueFormGondola("dimensions_y", gondolaInfo.dimensions_y)}

                            <Form.Label>External Id:</Form.Label>
                            <Form.Control type="text" placeholder="<external id>"
                                {...registerFormGondola("gondola_external_id")} />
                            <Form.Label className="mt-3">Position (x):</Form.Label>
                            <Form.Control type="text" {...registerFormGondola("img_pos_x")} />
                            <Form.Label className="mt-3">Position (y):</Form.Label>
                            <Form.Control type="text" {...registerFormGondola("img_pos_y")} />
                            <Form.Label className="mt-3">Dimension (x):</Form.Label>
                            <Form.Control type="text" {...registerFormGondola("dimensions_x")} />
                            <Form.Label className="mt-3">Dimension (y):</Form.Label>
                            <Form.Control type="text" {...registerFormGondola("dimensions_y")} />
                            <Form.Label className="mt-3">Rotation:</Form.Label>
                            <Form.Control type="text" {...registerFormGondola("yaw")} />
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {GENERIC_ERROR_MESSAGE}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showAddGatewayModal} onHide={handleCloseGatewayModal}>
                <Form noValidate onSubmit={handleSubmitFormGateway(onSubmitGateway)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Gateway</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.AddGateway">
                            <Form.Label>Mac Address:</Form.Label>
                            <Form.Control type="text" placeholder="<mac_address>" autoFocus
                                {...registerFormGateway("mac_address", { required: true })} />
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorAlertMessage}
                            </Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal backdrop="static" show={showDeleteGondolaModal} onHide={() => setShowDeleteGondolaModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Gondola</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this gondola ?
                    {showErrorAlert &&
                        <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                            {errorAlertMessage}
                        </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={onClickRemoveGondola} variant="primary">
                        Yes
                    </Button>
                    <Button type="submit" onClick={() => setShowDeleteGondolaModal(false)} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop="static" show={showDuplicateGondolaModal} onHide={() => setShowDuplicateGondolaModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Duplicate Gondola</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to duplicate this gondola ?
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={onClickDuplicateGondola} variant="primary">
                        Yes
                    </Button>
                    <Button type="submit" onClick={() => setShowDuplicateGondolaModal(false)} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal dialogAs={DraggableModalDialog} backdrop="static" show={showAddScaleModal} onHide={handleCloseScaleModal}>
                <Form noValidate onSubmit={handleSubmitFormScale(onSubmitScale)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Scales</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="form.AddScale">
                            <Form.Label>Serial Number:</Form.Label>
                            <Form.Control type="number" placeholder="<serial_number>" autoFocus
                                {...registerFormScale("serial_number", { required: true, valueAsNumber: true })} />
                        </Form.Group>
                        {showErrorAlert &&
                            <Alert className="mt-3" variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                {errorAlertMessage}
                            </Alert>}

                        {addScaleResultMessages.map((message, index) =>
                            <Alert variant={message.includes("Error") ? "danger" : "success"} className="mt-3" key={index}>{message}</Alert>
                        )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Add
                        </Button>
                        <Button type="submit" onClick={handleCloseScaleModal} variant="secondary">
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}